import styled from "styled-components";
import Badge from "react-bootstrap/Badge";

export const BookieCard = styled.div`
  background-color: #2A3B29;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  min-height: 500px;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const AddBookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  overflow: hidden;
`;

export const BookieNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-shrink: 0;
`;

export const BookieNameBadgesContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
`;

export const BookieName = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #E9FD8C;
  margin: 0;
  text-transform: capitalize;
`;

export const BookieNameBadge = styled(Badge)`
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 6px;
  background-color: ${props => {
    if (props.className === 'badge-warning') return '#FFA500';
    if (props.className === 'provider') return '#111E12';
    return '#3B4B39';
  }};
  color: ${props => {
    if (props.className === 'badge-warning') return '#1d2d1d';
    if (props.className === 'provider') return '#FFFFFF';
    return '#FFFFFF';
  }};
  border: none;
  transition: all 0.2s ease;
  text-transform: capitalize;
`;

export const BookieInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  max-height: 200px;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #1C2D1C;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3B4B39;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4B5B49;
  }

  h4 {
    color: #FFFFFF;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
  }

  p {
    color: #ABCDDF;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
  }

  a {
    color: #ABCDDF;
    text-decoration: none;
    &:hover {
      color: #FFFFFF;
      text-decoration: none;
    }
  }
`;

export const BookieWarning = styled(Badge)`
  display: block;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #FFA500;
  color: #1d2d1d;
  border-radius: 6px;
  border: none;
`;

export const AddBookieButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  flex-shrink: 0;
`;

export const AddBookieContainerButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const ActionButton = styled.button`
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &.addBookie {
    background-color: #E8FC8B;
    color: #1d2d1d;
    border: 3px solid #E8FC8B;

    &:hover {
      color: #007053;
      border: 3px solid #007053;
    }
  }

  &.enableBookie {
    background-color: ${props => props.style?.backgroundColor || '#94E5AB'};
    color: ${props => props.style?.color || '#1C2D1C'};

    &:hover {
      opacity: 0.9;
    }
  }
`;